import { doc, getFirestore } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestoreDocData } from "reactfire";
import Pancake from "../../assets/svgs/Pancake.svg";
import { LoadingSpinner } from "../../Components/Spinner";
import OrderReviewItem from "./OrderReviewItem";
import { Item } from "../../../types/Item";
import { useCallback, useEffect } from "react";

function OrderComplete() {
  const { restaurantId, orderId } = useParams();
  let navigate = useNavigate();
  const db = getFirestore();
  const pastOrderInfoRef = doc(
    db,
    `Restaurants/${restaurantId}/PastOrders/${orderId}`
  );
  const { status: pastOrderStatus, data: pastOrderInfo } = useFirestoreDocData(
    pastOrderInfoRef,
    {
      idField: "id",
    }
  );
  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);
  const { status, data: orderInfo } = useFirestoreDocData(orderInfoRef, {
    idField: "id",
  });

  const grabOrderItems = useCallback(
    () => {
      if (pastOrderInfo?.items?.length > 0) {
        return pastOrderInfo.items;
      }
      if (orderInfo?.items?.length > 0) {
        return orderInfo.items;
      }
      return [];
    },
    [pastOrderInfo, orderInfo],
  )

  const grabOrderInfo = useCallback(
    () => {
      if (pastOrderInfo?.items?.length > 0) {
        return pastOrderInfo;
      }
      if (orderInfo?.items?.length > 0) {
        return orderInfo;
      }
      return {};
    },
    [pastOrderInfo, orderInfo],
  )

  const grabOrderInfoRef = useCallback(
    () => {
      if (pastOrderInfo?.items?.length > 0) {
        return pastOrderInfoRef;
      }
      if (orderInfo?.items?.length > 0) {
        return orderInfoRef;
      }
      return {};
    },
    [pastOrderInfoRef, orderInfoRef],
  )

  

  useEffect(() => {
    if (status === "success") {
      if (orderInfo?.items?.length === 0) {
        navigate("/");
      }
    }
  }, [status, orderInfo, navigate]);

  return (
    <div className="flex min-h-screen justify-center text-center items-center p-0">
      <div
        className="relative min-h-screen transform w-screen overflow-hidden border dark:bg-dark-primary-background bg-white
        px-6 pt-2 pb-12 text-left shadow-xl transition-all max-w-lg"
      >
        <div className=" absolute top-0 right-0">
          <img src={Pancake} alt="Pancake" />
        </div>

        <div className="border border-eatout-ellipse-border-gray rounded-[50%] h-56 w-56 absolute -right-[55px] -top-[55px]" />
        <div className=" mb-5 pt-32">
          <p className="font-bold text-3xl text-green-text-title w-4/6 leading-9">
            {grabOrderInfo()?.status === "notified" ? "Your table is ready!" : "Enjoy!"}
          </p>
        </div>
        <p className="">
          <span className="font-bold">Your Order is being worked on</span><br/>
          If you would like to make any further changes to your order, please
          speak to your server
        </p>
        {status === "loading" || pastOrderStatus === "loading" ? (
          <LoadingSpinner />
        ) : (
          <div className="flex flex-col mt-10 pt-2 px-4 overflow-y-auto pb-1 bg-[#F7F7F7]">
            {grabOrderItems().map((item: Item, index: number) => {
              return (
                <OrderReviewItem
                  item={item}
                  orderInfoRef={grabOrderInfoRef()}
                  orderInfo={grabOrderInfo()}
                  key={item.name + index}
                  orderCompleteScreen={true}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderComplete;
