import { ArrowLeftIcon } from "@heroicons/react/solid";
import PartySubmission from "./PartySubmission";

export default function WaitListSubmission({
  handleResetForm,
  handleSubmit,
  partyQuantity,
  setPartyQuantity,
  firstName,
  lastName,
  phoneNumber,
  handleChange,
  formIsValid,
  note,
  showSpinner,
  setShowSpinner,
}: {
  handleResetForm: Function;
  handleSubmit: Function;
  partyQuantity: number;
  setPartyQuantity: Function;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  handleChange: Function;
  formIsValid: boolean | null;
  note: string;
  showSpinner: boolean;
  setShowSpinner: Function;
}) {
  return (
    <div className="mx-auto max-w-3xl bg-white overscroll-contain px-6 pt-5 pb-10">
      <div className="flex">
        <div className="-ml-3 mb-4 -mt-2 flex h-7 items-center">
          <button
            type="button"
            className="dark:text-dark-text-color text-light-text-color hover:text-gray-900 focus:outline-none"
            onClick={() => handleResetForm()}
          >
            <span className="sr-only">Close panel</span>
            <ArrowLeftIcon
              height={30}
              width={30}
              className=" text-light-text-color mt-3 text-black"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
      <div className="text-start sm:mt-0 sm:ml-4 sm:text-left">
        <h3 className="text-2xl font-bold">
          Join the Waitlist
        </h3>
      </div>
      <div className="mt-4 flex flex-col">
        <PartySubmission
          partyQuantity={partyQuantity}
          setPartyQuantity={setPartyQuantity}
          handleSubmit={handleSubmit}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          handleChange={handleChange}
          formIsValid={formIsValid}
          note={note}
          showSpinner={showSpinner}
          setShowSpinner={setShowSpinner}
        />
      </div>
    </div>
  );
}
