import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import howItWorks from "../../assets/svgs/howItWorks.svg";

export default function HowThisWorks({
  activated,
  setActivated,
}: {
  activated: boolean;
  setActivated: Function;
}) {
  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    setActivated(false);
    setActivated(false);
  };

  return (
    <Transition.Root show={activated} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y">
          <div className="flex min-h-full justify-center text-center items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="translate-y-0 scale-95"
              enterTo="scale-100"
              leave="ease-in duration-200"
              leaveFrom="scale-100"
              leaveTo="translate-y-0 scale-95"
            >
              <Dialog.Panel
                className="relative h-screen transform w-screen overflow-y-auto border dark:bg-dark-primary-background bg-white
              px-6 pt-2 pb-5 text-left shadow-xl transition-all max-w-lg"
              >
                <img
                  className=" absolute right-0 top-0"
                  src={howItWorks}
                  alt="pancakes"
                />
                <div className="flex">
                  <button
                    className="-mt-0.5 py-4 flex"
                    onClick={() => handleClose()}
                  >
                    <ArrowLeftIcon
                      className="h-6 w-6 dark:text-dark-text-color text-light-text-color mt-3"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                <div>
                  <h1 className="text-green-text-title font-bold text-2xl mb-4">
                    How This Works
                  </h1>
                  <ol className="pt-1">
                    <li className="flex">
                      <div className="flex">
                        <span className="font-bold">1. </span>
                        <div className=" ml-0.5">
                          <p className="font-bold">
                            Build your order while you wait.
                          </p>
                          <p className="inline">
                            Add some or all of your order ahead of time. 
                          </p>&nbsp;
                          <p className=" inline italic">
                            Just drinks? Go for it! Know your entrees? Let's do
                            it!
                          </p>
                          <p className="mt-1">
                            You can edit your order anytime before your table is
                            ready.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="flex py-2">
                      <div className="flex">
                        <span className="font-bold">2. </span>
                        <div className="ml-0.5">
                          <p className="font-bold">
                            We'll start preparing the order when your table is
                            ready.
                          </p>
                          <p>Don't worry, your food won't get cold.</p>
                        </div>
                      </div>
                    </li>
                    <li className="flex">

                      <div className="flex">
                        <span className="font-bold">3. </span>
                        <div className="ml-0.5">
                          <p className="font-bold">
                            Get notified when it's time to be seated.
                          </p>
                          <p>
                            Your server will help with extra ordering and
                            payment once you're seated.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ol>
                  <div className="bg-eatout-green p-4 my-2">
                    <h1 className="text-white font-thin mb-1.5">PRO-TIP</h1>
                    <p className=" text-white font-bold">
                      If your table is ready before you finish submitting your
                      order your cart won't be lost! Your server will confirm
                      those items and any additions with you before we start
                      working on it.
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
