import {
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import { useState } from "react";

interface Props {
  label?: string;
  labelClassName?: string;
  type?: string;
  placeholder?: string;
  htmlFor?: string;
  name: string;
  id: string;
  value: string;
  valid: Boolean | null;
  onChange: any;
  className?: string;
  onBlur?: Function;
  showPassword?: boolean;
  toggleShowPassword?: Function;
  labelLeftSide?: boolean;
  errorMessage?: string;
  tooltip?: JSX.Element;
  disabled?: boolean;
  onFocus?: Function;
}

const TextInput = (props: Props) => {
  const {
    label,
    value,
    valid,
    onChange,
    type = "text",
    id,
    placeholder = "",
    className = "block w-full border border-[#B8B8B8] p-1 pl-3 focus:outline-none rounded-md",
    name,
    htmlFor = "",
    labelClassName = "",
    onBlur,
    showPassword = false,
    toggleShowPassword,
    labelLeftSide = false,
    errorMessage = "",
    tooltip = null,
    disabled = false,
    onFocus
  } = props;
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  // This function is triggered on the keyup event
  const checkCapsLock = (event: any) => {
    if (event.getModifierState("CapsLock")) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const eyeBall = () => {
    return (
      <button
        type="button"
        onClick={() =>
          toggleShowPassword ? toggleShowPassword(!showPassword) : null
        }
        className={`absolute inset-y-0 right-0 text-white pr-3 flex items-center`}
      >
        {showPassword ? (
          <EyeOffIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <EyeIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </button>
    );
  };



  const inputFieldRender = () => {
    return (
      <>
        <div
          className={`rounded-md`}
        >
          {type === "password" ? eyeBall() : null}
          <input
            type={showPassword && type === "password" ? "text" : type}
            onKeyUp={checkCapsLock}
            name={name}
            id={id}
            className={`text-black disabled:text-gray-400 mb-2 ${
              valid === true
                ? ""
                : "focus:ring-error-red focus:border-error-red placeholder:text-error-red border-error-red"
            } ${className}`}
            placeholder={placeholder}
            value={value}
            aria-invalid={valid ? true : false}
            aria-describedby={`${label} error`}
            onChange={onChange}
            onBlur={(e) => (onBlur ? onBlur(e) : null)}
            disabled={disabled}
            onFocus={(e) => (onFocus ? onFocus(true) : null)}
            autoFocus={false}
          />
        </div>
        {type === "password"
          ? isCapsLockOn && (
              <>
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-white inline mr-1 mb-1"
                >
                  <path
                    d="M6.5 7V4H7.585L5 1.415L2.415 4H3.5V7H6.5ZM5 0L10 5H7.5V8H2.5V5H0L5 0ZM2.5 9H7.5V12H2.5V9ZM6.5 10H3.5V11H6.5V10Z"
                    fill="#FCFCFC"
                  />
                </svg>
                <p className="text-white inline">CAPS LOCK ON</p>
              </>
            )
          : null}
        {valid === null ? null : valid === true ? null : (
          <div className="rounded-md bg-error-bg py-2 text-error-red pr-2 mt-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="inline mr-1 mb-1"
            >
              <path
                d="M13 13H11V7H13M13 17H11V15H13M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                fill="#FF3131"
              />
            </svg>
            {errorMessage}
          </div>
        )}
        {tooltip ? tooltip : null}
      </>
    );
  };

  return (
    <>
      {label ? (
        labelLeftSide ? null : (
          <label htmlFor={htmlFor} className={`${labelClassName} text-black py-1.5`}>
            {label}
          </label>
        )
      ) : null}

      {labelLeftSide ? (
        <>
          <label htmlFor={htmlFor} className={`${labelClassName} text-black`}>
            {label}
          </label>
          {inputFieldRender()}
        </>
      ) : (
        <div className="relative rounded-md">
          {inputFieldRender()}
        </div>
      )}
    </>
  );
};

export default TextInput;
