import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { Item } from "../../types/Item";

const ItemCard = ({
  menu,
  orderScreen,
  setOpenOrderModal,
  setOrderItem,
  searchText,
  orderInfoItems,
  index,
}: {
  menu: any;
  orderScreen: boolean;
  setOpenOrderModal: Function;
  setOrderItem: Function;
  scrollContainerRef: any;
  index: number;
  searchText?: string;
  orderInfoItems?: any[];
}) => {
  const { restaurantId, orderId } = useParams();

  const itemTabRef = useRef<HTMLDivElement>(null);
  const db = useFirestore();
  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);
  const { data: orderInfo } = useFirestoreDocData(orderInfoRef);

  const addItem = async (item: Item) => {
    const currentOrderItems = orderInfo?.items;

    if (currentOrderItems) {
      const foundItem = orderInfo.items.find((orderItem: Item) => {
        if (orderItem.name === item.name) {
          if (
            orderItem?.modification &&
            orderItem.modification !== item.modification
          ) {
            return undefined;
          }
          if (item?.variants && item.variants !== orderItem.variants) {
            return undefined;
          }
          orderItem.quantity = orderItem.quantity ? orderItem.quantity + 1 : 1;
          return orderItem;
        }

        return null;
      });

      if (!foundItem) {
        await updateDoc(orderInfoRef, {
          items: arrayUnion({
            ...item,
            quantity: item?.quantity ? item.quantity + 1 : 1,
            modification: "",
          }),
        });
      } else {
        await updateDoc(orderInfoRef, {
          items: currentOrderItems,
        });
      }

      if (toast.isActive(item.name)) {
        toast.dismiss(`${item.name}`);
      }
      toast.success(`${item.name} was added to your order!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        closeButton: false,
        draggable: true,
        progress: undefined,
        toastId: `${item.name}`,
        className:
          "!border-l-8 !border-l-eatout-notification-green !rounded-l-lg mt-6",
        bodyClassName: "",
        icon: (
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z"
              fill="#45AF2D"
            />
          </svg>
        ),
      });
    }
    return false;
  };

  const restaurantItemsList = useCallback(() => {
    if (searchText && searchText?.length > 2) {
      const filtered = menu.filter(
        (item: any) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.description?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.classification.toLowerCase().includes(searchText.toLowerCase())
      );
      return filtered;
    }
    return menu;
  }, [searchText, menu]);

  // display the quantity of the item in the order
  const displayQuantity = (item: any) => {
    if (orderInfoItems) {
      let sum = 0;
      orderInfoItems.forEach((orderItem: any) => {
        if (orderItem.name === item.name) {
          sum += orderItem.quantity;
        }
      });
      return sum;
    }
    return 0;
  };

  return (
    <div key={index + "item-cards-section"} className="card-section">
      {restaurantItemsList()?.map((item: any, index: number) => (
        <div
          className="border-b border-eatout-border-gray py-4 pl-1"
          key={item.name + index + "-item-card"}
          ref={index === 0 ? itemTabRef : null}
        >
          <div className="pl-2 grid grid-cols-9 sm:grid-cols-14 gap-1">
            {orderScreen ? (
              <button
                className={`outline-none focus:outline-none flex flex-col justify-start ${
                  orderScreen && item.price ? "col-span-8" : "col-span-9"
                } sm:col-span-13`}
                onClick={() => {
                  if (orderScreen) {
                    setOrderItem(item);
                    setOpenOrderModal(true);
                  } else {
                    return true;
                  }
                }}
              >
                <div className="flex flex-row w-full justify-between">
                  <p className="font-bold mb-1 text-left capitalize">
                    {item.name}
                  </p>
                  <p className="text-left text-sm mr-1 justify-end">
                    {item?.price || item?.name === "Water" ? (
                      item?.name === "Water" ? '' : `$${item.price.toFixed(2)}`
                    ) : item.classification === "drinks" ? (
                      <strong>TBD</strong>
                    ) : (
                      <div className="relative">
                        <strong>Choice Required</strong>{" "}
                        <>
                          {displayQuantity(item) && orderScreen ? (
                            <p className=" absolute right-0 top-6 text-center text-black font-bold">
                              <span className=" text-xs">x</span>
                              {displayQuantity(item)}
                            </p>
                          ) : null}
                        </>
                      </div>
                    )}
                  </p>
                </div>

                {item.description ? (
                  // truncate the descriptin if it's past 50 characters
                  item.description.length > 28 ? (
                    <p className="mb-2 text-left text-sm w-3/4 h-5 overflow-hidden">
                      {item.description.substring(0, 28) + "..."}
                    </p>
                  ) : (
                    <p className="mb-2 text-left text-sm">{item.description}</p>
                  )
                ) : null}
              </button>
            ) : (
              <div
                className={`flex flex-col justify-start ${
                  orderScreen ? "col-span-8" : "col-span-9"
                } sm:col-span-13`}
                onClick={() => {
                  if (orderScreen) {
                    setOrderItem(item);
                    setOpenOrderModal(true);
                  } else {
                    return true;
                  }
                }}
              >
                <div className="flex flex-row w-full justify-between">
                  <p className="font-bold mb-1 text-left">{item.name}</p>
                  <p className="text-left text-sm mr-1 justify-end">
                    {item?.price || item?.name === "Water" ? (
                      `$${item.price.toFixed(2)}`
                    ) : (
                      <strong>Choice required</strong>
                    )}
                  </p>
                </div>

                {item.description ? (
                  // truncate the descriptin if it's past 50 characters
                  item.description.length > 40 ? (
                    <p className="mb-2 text-left text-sm w-3/4">
                      {item.description.substring(0, 40) + "..."}
                    </p>
                  ) : (
                    <p className="mb-2 text-left text-sm">{item.description}</p>
                  )
                ) : null}
              </div>
            )}

            {orderScreen && item?.price ? (
              <div className="flex flex-col col-span-1">
                <button
                  className="focus:outline-none focus:bg-transparent pl-1 -mt-0.5"
                  onClick={() => {
                    if (item?.variant?.length || item?.sideRequired || item?.options?.length) {
                      setOrderItem(item);
                      setOpenOrderModal(true);
                    } else {
                      addItem(item);
                    }
                  }}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="focus:outline-none focus:bg-transparent"
                  >
                    <path
                      d="M22.5 16.5H16.5V22.5H13.5V16.5H7.5V13.5H13.5V7.5H16.5V13.5H22.5M15 0C13.0302 0 11.0796 0.387987 9.25975 1.14181C7.43986 1.89563 5.78628 3.00052 4.3934 4.3934C1.58035 7.20644 0 11.0218 0 15C0 18.9782 1.58035 22.7936 4.3934 25.6066C5.78628 26.9995 7.43986 28.1044 9.25975 28.8582C11.0796 29.612 13.0302 30 15 30C18.9782 30 22.7936 28.4196 25.6066 25.6066C28.4196 22.7936 30 18.9782 30 15C30 13.0302 29.612 11.0796 28.8582 9.25975C28.1044 7.43986 26.9995 5.78628 25.6066 4.3934C24.2137 3.00052 22.5601 1.89563 20.7403 1.14181C18.9204 0.387987 16.9698 0 15 0Z"
                      fill="#F6AE2D"
                    />
                  </svg>
                </button>
                {displayQuantity(item) && orderScreen ? (
                  <p className=" relative text-center text-black font-bold mt-4">
                    <span className=" text-xs">x</span>
                    {displayQuantity(item)}
                  </p>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemCard;
