import { ChangeEvent, useState } from "react";

export default function HomeHeader() {
  const [searchText, setSearchText] = useState("");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  return (
    <div className="max-w-7xl px-4 sm:px-6 lg:px-8 bg-header-primary-background pt-14 pb-2 max-h-56">
      <h1 className="sr-only">EatOut</h1>
      <h1 className="text-white font-bold text-5xl">EatOut</h1>
      <h3 className="text-white text-xl font-bold mt-4">Order ahead!</h3>
      <p className="text-white font-ligh mt-1 mb-2 w-3/4">
        Save time and build your order while you wait
      </p>
      <div className=" relative -right-[190px] -top-[280px]">
        <svg
          width="250"
          height="235"
          viewBox="0 0 250 235"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M224.5 108.5C224.5 168.13 174.373 216.5 112.5 216.5C92.9995 216.5 74.6658 211.695 58.7004 203.248C38.1979 183.672 25.5 156.512 25.5 126.5C25.5 66.8702 75.6269 18.5 137.5 18.5C157 18.5 175.334 23.3047 191.3 31.7523C211.802 51.3283 224.5 78.488 224.5 108.5ZM24.5 126.5C24.5 155.55 36.2788 181.939 55.4767 201.474C22.5608 182.661 0.5 148.058 0.5 108.5C0.5 48.8702 50.6269 0.5 112.5 0.5C141.884 0.5 168.619 11.4093 188.594 29.253C173.248 21.7363 155.885 17.5 137.5 17.5C75.109 17.5 24.5 66.2841 24.5 126.5ZM194.523 33.5257C227.439 52.3385 249.5 86.9416 249.5 126.5C249.5 186.13 199.373 234.5 137.5 234.5C108.116 234.5 81.3811 223.591 61.4064 205.747C76.7521 213.264 94.1145 217.5 112.5 217.5C174.891 217.5 225.5 168.716 225.5 108.5C225.5 79.4499 213.721 53.0605 194.523 33.5257Z"
            stroke="#DBDBDB"
          />
          <path
            d="M224.5 108.5C224.5 168.13 174.373 216.5 112.5 216.5C92.9995 216.5 74.6658 211.695 58.7004 203.248C38.1979 183.672 25.5 156.512 25.5 126.5C25.5 66.8702 75.6269 18.5 137.5 18.5C157 18.5 175.334 23.3047 191.3 31.7523C211.802 51.3283 224.5 78.488 224.5 108.5ZM24.5 126.5C24.5 155.55 36.2788 181.939 55.4767 201.474C22.5608 182.661 0.5 148.058 0.5 108.5C0.5 48.8702 50.6269 0.5 112.5 0.5C141.884 0.5 168.619 11.4093 188.594 29.253C173.248 21.7363 155.885 17.5 137.5 17.5C75.109 17.5 24.5 66.2841 24.5 126.5ZM194.523 33.5257C227.439 52.3385 249.5 86.9416 249.5 126.5C249.5 186.13 199.373 234.5 137.5 234.5C108.116 234.5 81.3811 223.591 61.4064 205.747C76.7521 213.264 94.1145 217.5 112.5 217.5C174.891 217.5 225.5 168.716 225.5 108.5C225.5 79.4499 213.721 53.0605 194.523 33.5257Z"
            stroke="#DBDBDB"
          />
        </svg>
      </div>
      {/* <div className="mt-2 relative">
        <div className="pointer-events-none absolute mt-5 inset-y-0 left-0 flex items-center pl-2 pb-6 dark:text-dark-text-color text-light-text-color">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <input
          type={"text"}
          name={"search-field"}
          className={`dark:text-dark-text-color text-light-text-color bg-white outline-none font-secondary
            block w-full pr-10 focus:outline-none 
            placeholder:dark:text-dark-text-color placeholder:text-light-text-color
            pl-8
            sm:text-sm rounded-md border border-1 border-white py-2 px-2 mb-6`}
          placeholder={"What are you hungry for?"}
          defaultValue={searchText}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
        />
      </div> */}
    </div>
  );
}
