import { ChangeEvent } from "react";

export default function SearchInput({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: Function;
}) {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  return (
    <>
      <div className="flex flex-row mt-4">
        <div className="w-full relative">
          <input
            type={"text"}
            name={"search-field"}
            className={`bg-transparent outline-none
            block w-full pr-10 focus:outline-none
            placeholder:dark:text-dark-text-color placeholder:text-light-text-color
            pl-2.5
            sm: rounded-md border border-black py-2 px-2 mb-6`}
            placeholder={"What are you looking for?"}
            defaultValue={searchText}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
          />
          <div className="pointer-events-none absolute inset-y-0 right-[9px] flex items-center pb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
