import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useFirestore } from "reactfire";
import AddToOrderModal from "./modals/AddToOrder";
import { Item } from "../../types/Item";
import ItemCard from "./ItemCard";

const TabsListOrder = [
  "Appetizers",
  "Classics",
  "Wings&Tenders",
  "Salads",
  "Soups",
  "Dillas&Tacos",
  "Pastas",
  "Steaks",
  "Chicken",
  "Sandwiches",
  "Seafood",
  "Burgers",
  "Wraps",
  "Sweets",
  "Kids",
  "Sides",
  "SoftDrinks",
  "Beer&Wine",
  "Cocktails",
  "Whiskey'sFlights"
];
const TabHeight = 350;
const scrollAnchors: number[] = [0];

export default function ItemsTab({
  orderScreen,
  searchText,
  orderInfoItems,
  menu,
}: {
  orderScreen: boolean;
  searchText?: string;
  orderInfoItems?: any[];
  menu: any;
}) {
  const db = useFirestore();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const tabListRef = useRef<HTMLDivElement>(null);
  const [orderItem, setOrderItem] = useState<Item>({
    id: "",
    name: "",
    description: "",
    price: 0,
    image: "",
    classification: "app"
  });

  // Handle the scroll event on the container element
  const handleScroll = () => {
    // Update the scroll position state variable
    setScrollPosition(scrollContainerRef.current?.scrollTop || 0);
  };

  const scrollToOnClick = (index: number) => {
    scrollToItem(scrollContainerRef, getItemPositions(scrollContainerRef), index)
  };

  function getItemPositions(scrollableElementRef: React.RefObject<HTMLDivElement>): number[] {
    const scrollableElement = scrollableElementRef.current;
    if (!scrollableElement) {
      return [];
    }

    const itemElements = scrollableElement.querySelectorAll('.card-section');
    const itemPositions = [];

    let currentPosition = 0;
    for (let i = 0; i < itemElements.length; i++) {
      const itemElement = itemElements[i];
      const itemHeight = itemElement.getBoundingClientRect().height;
      itemPositions.push(currentPosition);
      currentPosition += itemHeight;
    }

    return itemPositions;
  }

  function scrollToItem(scrollableElementRef: React.RefObject<HTMLDivElement>, itemPositions: number[], targetIndex: number) {
    const scrollableElement = scrollableElementRef.current;
    if (!scrollableElement) {
      return;
    }

    const currentPosition = scrollableElement.scrollTop;
    const targetPosition = itemPositions[targetIndex];
    const distance = Math.abs(targetPosition - currentPosition);
    const duration = Math.min(100, distance / 2);

    const start = performance.now();
    const animateScroll = (timestamp: number) => {
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / duration, 1);
      const position = currentPosition + (targetPosition - currentPosition) * progress;
      scrollableElement.scrollTo(0, position);
      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  }

  // Use the useRef hook to get a reference to the scrollable container element
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const tabContainerRef = useRef<HTMLDivElement>(null);

  // Use the useEffect hook to track changes to the scroll position
  useEffect(() => {
    // Get the current scroll position
    const scrollPosition = scrollContainerRef.current?.scrollTop || 0;

    // Calculate the index of the selected tab based on the scroll position
    const itemPositions = getItemPositions(scrollContainerRef);
    const selectedIndex = itemPositions.findIndex((itemPosition, index) => {
      const nextItemPosition = itemPositions[index + 1];
      return scrollPosition >= itemPosition && scrollPosition < (nextItemPosition || Infinity);
    });

    // Update the selected tab
    setSelectedTab(selectedIndex);
  }, [scrollPosition]);

  useEffect(() => {

    const timeoutId = setTimeout(() => {
      const selectedTabElement = document.querySelector(
        `.tab:nth-child(${selectedTab + 1})`
      );

      if (selectedTabElement) {
        selectedTabElement.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "center",
        });
      }
    }, 0.42069);

    return () => clearTimeout(timeoutId);
  }, [selectedTab]);

  return (
    <div>
      <AddToOrderModal
        activated={openOrderModal}
        setActivated={setOpenOrderModal}
        item={orderItem}
        db={db}
        menu={menu}
      />
      {!menu ? null : (
        <>
          <div
            className={
              "flex border-b border-eatout-border-gray mx-5 overflow-x-scroll"
            }
            ref={tabContainerRef}
          >
            {searchText && searchText?.length > 2 ? (
              <p className="font-bold">Search Results</p>
            ) : (
              <>
                {TabsListOrder.map((i) => {
                  return { id: i };
                }).map((data: any, index: number) => {
                  return (
                    <button
                      className={`tab outline-none mx-2 ${selectedTab === index ||
                        (index === menu?.length &&
                          selectedTab > menu?.length) ||
                        (!selectedTab && index === 0)
                        ? "font-bold"
                        : ""
                        }`}
                      onClick={() => scrollToOnClick(index)}
                      key={index + "tab-button"}
                    >
                      <span className="whitespace-nowrap">{data?.id.replace(/(?<!^)([A-Z&])/g, " $1")}</span>
                      <div
                        className={`h-1.5 mt-2 ${selectedTab === index ||
                          (index === menu?.length &&
                            selectedTab > menu?.length) ||
                          (!selectedTab && index === 0)
                          ? "bg-eatout-green"
                          : "bg-transparent"
                          } rounded-t-lg`}
                      ></div>
                    </button>
                  );
                })}
              </>
            )}
          </div>
          <div
            className={"px-5 pt-5 overflow-y-auto h-screen pb-24"}
            ref={scrollContainerRef}
            onScroll={handleScroll}
          >
            {TabsListOrder.map((i) => menu[i]).map(
              (data: any, index: number) => {
                return (
                  <ItemCard
                    orderScreen={orderScreen}
                    menu={index === (TabsListOrder.length - 2) ? data.sort((a: any, b: any) => {
                      if (a?.name?.toLowerCase() === 'custom cocktail') {
                        return 1; // Place custom cocktail at the end
                      } else if (b?.name?.toLowerCase() === 'custom cocktail') {
                        return -1; // Place custom cocktail at the end
                      } else {
                        return a?.name?.localeCompare(b?.name); // Sorting the rest alphabetically
                      }
                    }) : data}
                    setOpenOrderModal={setOpenOrderModal}
                    setOrderItem={setOrderItem}
                    scrollContainerRef={scrollContainerRef}
                    index={index}
                    searchText={searchText}
                    orderInfoItems={orderInfoItems}
                    key={index + "item-cards"}
                  />
                );
              }
            )}
          </div>
        </>
      )}
    </div>
  );
}
