import { ThreeDots } from "react-loader-spinner";

export const LoadingSpinner = ({ height = null }) => {
  return (
    <div
      className={`flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 opacity-50 bg-black z-50`}
      style={{ height: height ? height : "100vh" }}
      id={"spinner"}
    >
      <ThreeDots color="#00BFFF" height={80} width={80} />
    </div>
  );
};
