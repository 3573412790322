import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Item } from "../../../types/Item";
import QuantityInput from "../QuantityInput";
import { useParams } from "react-router-dom";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { useFirestoreDocData } from "reactfire";
import { toast } from "react-toastify";
import { RadioButtons } from "../RadioButtons";
import { OptionsDropdown } from "../OptionsDropdown";

const sidesRequired = ["soups-and-salads", "wings-and-tenders", "classics", "steaks"];

export default function AddToOrderModal({
  activated,
  setActivated,
  item,
  db,
  menu,
}: {
  activated: boolean;
  setActivated: Function;
  // address this type issue later
  item: any;
  db: any;
  menu?: any;
}) {
  const { restaurantId, orderId } = useParams();
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [specialRequest, setSpecialRequest] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedTemp, setSelectedTemp] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedExtraOption, setExtraOption] = useState("");
  const [selectedAddOn, setSelectedAddOn] = useState("");
  const [selectedSide, setSelectedSide] = useState("");
  const [selectedExtraSide, setSelectedExtraSide] = useState('');
  const [selectedDippingSauce, setSelectedDippingSauce] = useState("");
  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);
  const { data: orderInfo } = useFirestoreDocData(orderInfoRef);

  const cancelButtonRef = useRef(null);

  const [itemPrice, setItemPrice] = useState(item?.price || 0);
  const itemVariants = item?.variants ? Object.keys(item.variants) : [];
  const variants = item?.variants ? item.variants : {};
  const tempratureVariants = item?.temperature?.length
    ? item.temperature.split(",")
    : [];
  const options = item?.options?.length ? item.options.split(",") : [];
  const dippingSauce = item?.dippingSauce ? item.dippingSauce.split(",") : [];
  const addOn = item?.addOn ? Object.keys(item.addOn) : [];
  const sides =
    menu?.Sides?.length && (item?.allowAddOn || item?.sideRequired)
      ? menu.Sides.sort((a: any, b: any) => {
        if (a.upcharge === 0) return -1;
        if (b.upcharge === 0) return 1;
        return b.upcharge - a.upcharge;
      })
      : [];

  const toastError = (errorMessage: string) => {
    return toast.error(errorMessage, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      closeButton: false,
      draggable: true,
      progress: undefined,
      toastId: `${item.name}`,
      className:
        "!border-l-8 !border-l-eatout-notification-red !rounded-l-lg mt-6 border-red-500",
      bodyClassName: "",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="red"
          viewBox="0 0 24 24"
          stroke="white"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      ),
    });
  };

  const addItem = async () => {
    const currentOrderItems = orderInfo?.items;

    // this might be trash
    if (
      sidesRequired.includes(item.classification) &&
      selectedOption === "" &&
      item?.options?.length
    ) {
      return toastError("Please select an option");
    }

    if (item?.options?.length && selectedOption === "") {
      if (!item?.optionOptional) {
        return toastError("Please select an option");
      }
    }

    if (item?.sideRequired && selectedSide === "") {
      return toastError("Please select a side");
    }

    if (item?.sideLimit > 1 && selectedExtraSide === "") {
      return toastError("Please select a 2nd side");
    }

    if (dippingSauce?.length && selectedDippingSauce === "") {
      return toastError("Please select a dipping sauce");
    }

    if (currentOrderItems) {
      const foundItem = orderInfo.items.find((orderItem: Item) => {
        if (orderItem.name === item.name) {
          if (specialRequest && specialRequest !== orderItem.modification) {
            return undefined;
          }
          if (item?.variants && item.variants !== orderItem.variants) {
            return undefined;
          }
          orderItem.quantity = orderItem.quantity
            ? orderItem.quantity + quantity
            : 1;
          return orderItem;
        }

        return null;
      });

      if (!foundItem) {
        console.log('new item added!: ', selectedExtraOption);
        await updateDoc(orderInfoRef, {
          items: arrayUnion({
            ...item,
            quantity: quantity,
            // @ts-ignore
            variant: selectedVariant || "",
            option: selectedOption || "",
            extraOption: selectedExtraOption || "",
            temperature: selectedTemp || "",
            price: itemPrice || item.price,
            modification: specialRequest,
            addOn: selectedAddOn || "",
            side: selectedSide || "",
            extraSide: selectedExtraSide || "",
            dippingSauce: selectedDippingSauce || "",
          }),
        });
      } else {
        await updateDoc(orderInfoRef, {
          items: currentOrderItems,
        });
      }

      if (toast.isActive(item.name)) {
        toast.dismiss(`${item.name}`);
      }
      toast.success(`${item.name} was added to your order!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        closeButton: false,
        draggable: true,
        progress: undefined,
        toastId: `${item.name}`,
        className:
          "!border-l-8 !border-l-eatout-notification-green !rounded-l-lg mt-6",
        bodyClassName: "",
        icon: (
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z"
              fill="#45AF2D"
            />
          </svg>
        ),
      });
      handleClose();
    }
    return false;
  };

  const handleClose = () => {
    setOpen(false);
    setActivated(false);
    setTimeout(() => {
      setQuantity(1);
      setSelectedVariant("");
      setSpecialRequest("");
      setSelectedAddOn("");
      setSelectedOption("");
      setExtraOption("");
      setSelectedTemp("");
      setSelectedSide("");
      setSelectedDippingSauce("");
      setSelectedExtraSide("");
      setItemPrice(item?.price || 0);
    }, 500);
  };

  function handleDropdownChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedOption(e.target.value);
  }

  function handleDropdownExtraOption(e: React.ChangeEvent<HTMLSelectElement>) {
    setExtraOption(e.target.value);
  };

  function handleDropdownTempChange(
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    setSelectedTemp(e.target.value);
  }

  function handleDropdownChangeDippingSauce(
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    setSelectedDippingSauce(e.target.value);
  }

  function handleDropdownChangeSide(e: React.ChangeEvent<HTMLSelectElement>) {
    const side = JSON.parse(e.target.value);
    setSelectedSide(side);

    // @ts-ignore
    let newPrice = selectedVariant.price || item.price;

    if (side?.upcharge) {
      newPrice = parseFloat((newPrice + side.upcharge).toFixed(2));
    }

    // @ts-ignore
    if (selectedExtraSide?.upcharge) {
      // @ts-ignore
      newPrice = parseFloat((newPrice + selectedExtraSide.upcharge).toFixed(2));
    }

    setItemPrice(newPrice);
  }

  const handleDropdownChangeExtraSide = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const side = JSON.parse(e.target.value);
    setSelectedExtraSide(side);

    // @ts-ignore
    let newPrice = selectedVariant.price || item.price;

    // @ts-ignore
    if (selectedSide?.upcharge) {
      // @ts-ignore
      newPrice = parseFloat((newPrice + selectedSide.upcharge).toFixed(2));
    }

    // @ts-ignore
    if (side?.upcharge) {
      // @ts-ignore
      newPrice = parseFloat((newPrice + side.upcharge).toFixed(2));
    }

    setItemPrice(newPrice);
  };


  const handleSelectVariantChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const variant = JSON.parse(e.target.value);

    let newPrice = variant?.price || item.price;

    // @ts-ignore
    if (selectedSide?.upcharge) {
      // @ts-ignore
      newPrice = parseFloat((newPrice + selectedSide.upcharge).toFixed(2));
    }

    // @ts-ignore
    if (selectedExtraSide?.upcharge) {
      // @ts-ignore
      newPrice = parseFloat((newPrice + selectedExtraSide.upcharge).toFixed(2));
    }

    setSelectedVariant(variant);
    setItemPrice(newPrice);
  };

  const handledSelectAddon = (variant: string) => {
    if (selectedAddOn === variant) {
      if (item?.name === "THE ALL AMERICAN") {
        setSelectedOption("");
      }
      setSelectedAddOn("");
      setItemPrice(item?.price || 0);
      return;
    }
    setSelectedAddOn(variant);
    setItemPrice(parseFloat(itemPrice + item?.addOn?.[variant]).toFixed(2));
  };

  useEffect(() => {
    setOpen(activated);
    setItemPrice(item?.price || 0);
  }, [activated]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="z-10 fixed bottom-0"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            id="addToOrderModal"
            className="flex min-h-full justify-center w-full pt-20 text-center sm:p-0"
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200"
              enterFrom="transform transition translate-y-1/2"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-700"
              leaveFrom="transform transition translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel
                className="relative min-h-full transform w-screen overflow-hidden border dark:bg-dark-primary-background bg-white
                px-6 pt-2 pb-12 text-left shadow-xl transition-all max-w-lg rounded-t-3xl"
              >
                <div className="-ml-3 flex h-7 justify-end">
                  <button
                    type="button"
                    className="dark:text-dark-text-color text-light-text-color hover:text-gray-900 focus:outline-none"
                    onClick={() => handleClose()}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-4 mb-5">
                  <h2 className="font-bold text-2xl mb-5">{item.name}</h2>

                  {
                    // @ts-ignore
                    item["category-note"] && (
                      <p
                        className={`my-4 ${item.classification === "lunch" ? "text-red-500" : ""
                          } font-bold`}
                      >
                        {item["category-note"]}
                      </p>
                    )
                  }
                  <p className="mb-5">
                    {itemPrice ? `$${itemPrice}` : <strong>{item?.name == "Water" ? "Free" : "TBD"}</strong>}
                  </p>
                  {item.description ? (
                    <p className="mb-2 text-left">{item.description}</p>
                  ) : null}
                  {variants && Array.isArray(variants) ? (
                    <>
                      <OptionsDropdown
                        title="Select Option (required)"
                        options={variants}
                        selectedOption={selectedVariant}
                        onChange={handleSelectVariantChange}
                        isOption={false}
                      />
                    </>
                  ) : null}

                  {addOn?.length ? (
                    <>
                      <h2 className="font-bold text-lg my-5">Add On</h2>
                      {addOn.map((variant) => (
                        <div
                          className="flex items-center mb-4"
                          key={`option-${variant}`}
                        >
                          <input
                            id={`radio-${variant}`}
                            type="radio"
                            value={variant}
                            checked={variant === selectedAddOn}
                            onClick={() => handledSelectAddon(variant)}
                            name={`radio-${variant}`}
                            className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 checked:bg-eatout-green checked:border-transparent focus:ring-eatout-green`}
                          />
                          <label
                            htmlFor={`radio-${variant}`}
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {`${variant.replaceAll("-", " ")} : $${item?.addOn?.[variant]
                              }`}
                          </label>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {tempratureVariants?.length ? (
                    <OptionsDropdown
                      title={"Select Temperature (required)"}
                      options={tempratureVariants}
                      selectedOption={selectedTemp}
                      onChange={handleDropdownTempChange}
                      isOption={true}
                    />
                  ) : null}

                  {/* Temporary Option handling for BW American Burger */}
                  {options?.length && item?.name !== "THE ALL AMERICAN" ? (
                    (<OptionsDropdown
                      title="Select Option (required)"
                      options={options}
                      selectedOption={selectedOption}
                      onChange={handleDropdownChange}
                    />)
                  ) : null}

                  {options?.length && item?.name === "THE ALL AMERICAN" && selectedAddOn !== "" ? (
                    (<OptionsDropdown
                      title="Select Option (required)"
                      options={options}
                      selectedOption={selectedOption}
                      onChange={handleDropdownChange}
                    />)
                  ) : null}

                  {item?.optionsLimit > 1 ? (
                    <OptionsDropdown
                      title={`2nd Option Choice`}
                      options={options}
                      selectedOption={selectedExtraOption}
                      onChange={handleDropdownExtraOption}
                    />
                  ) : null}

                  {dippingSauce?.length ? (
                    <OptionsDropdown
                      title={"Select Dipping Sauce (required)"}
                      options={dippingSauce}
                      selectedOption={selectedDippingSauce}
                      onChange={handleDropdownChangeDippingSauce}
                      isOption={true}
                    />
                  ) : null}

                  {item.sideRequired || item.allowAddOn ? (
                    // Future Fix - this is ridiculous
                    item.classification === "kids" ?
                      (
                        <OptionsDropdown
                          title={`Side Choices ${item?.allowAddOn ? "" : "(required)"
                            }`}
                          options={sides.filter((x: { name: string; }) => x?.name !== "Mac N Cheese")}
                          selectedOption={selectedSide}
                          onChange={handleDropdownChangeSide}
                          isOption={false}
                        />
                      ) : (
                        <OptionsDropdown
                          title={`Side Choices ${item?.allowAddOn ? "" : "(required)"
                            }`}
                          options={sides}
                          selectedOption={selectedSide}
                          onChange={handleDropdownChangeSide}
                          isOption={false}
                        />
                      )
                  ) : null}

                  {item?.sideLimit > 1 ? (
                    <OptionsDropdown
                      title={`2nd Side Choice`}
                      options={sides}
                      selectedOption={selectedExtraSide}
                      onChange={handleDropdownChangeExtraSide}
                      isOption={false}
                    />
                  ) : null}

                  <div className="flex flex-col w-full pt-4 mb-5">
                    <label htmlFor={item.name} className={"font-semibold"}>
                      Special Requests
                    </label>
                    <textarea
                      id={item.name}
                      className="bg-[#F2F2F2] rounded-md p-2 mt-2 resize-none h-32"
                      placeholder="Write any additional notes here"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setSpecialRequest(e.target.value)
                      }
                    />
                  </div>
                  <QuantityInput
                    quantity={quantity}
                    setQuantity={setQuantity}
                    styling={"justify-center mb-5"}
                  />
                  <button
                    className="bg-wait-time-button rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                    onClick={() => addItem()}
                    disabled={itemVariants?.length > 1 && !selectedVariant}
                  >
                    <span className="text-black text-lg font-bold inline">
                      + Add to Order |{" "}
                      {itemPrice ? `$${itemPrice}` : <strong>{item?.name == "Water" ? "Free" : "TBD"}</strong>}
                    </span>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
