import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import { Listbox } from "@headlessui/react";
import { DateTime } from "luxon";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function TimeListBox(
  timeInterval: DateTime[],
  selected: DateTime,
  setSelected: (value: DateTime) => any
) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button
              className={`relative w-full cursor-default ${
                open ? "rounded-t-md" : "rounded-md"
              } bg-eatout-select-gray py-2 pl-3 pr-10 text-left`}
            >
              <span className="block truncate">
                {selected?.toFormat("hh:mm a").toLocaleString()}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute max-h-60 w-full overflow-auto rounded-b-md bg-eatout-select-gray pb-1 text-base">
                {timeInterval.map((value) => (
                  <Listbox.Option
                    key={value.toFormat("hh:mm a").toLocaleString()}
                    className={
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    }
                    value={value}
                    disabled={DateTime.local().valueOf() > value.valueOf()}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {value.toFormat("hh:mm a").toLocaleString()}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default function DelaySeatingModal({
  activated,
  setActivated,
  timeInterval,
  timeOfQuote
}: {
  activated: boolean;
  setActivated: Function;
  timeInterval: DateTime[];
  timeOfQuote: DateTime
}) {
  const [selected, setSelected] = useState(timeInterval[0]);
  const { restaurantId, orderId } = useParams();
  const db = getFirestore();

  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);

  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    setActivated(false);
  };

  const handleDelaySubmission = async () => {
    await updateDoc(orderInfoRef, {
      delaySubmitted: selected.valueOf(),
    });
    handleClose();
  }

  const handleTimeDropdown = useCallback(
    () => {
      return TimeListBox(timeInterval, selected, setSelected)
    },
    [timeInterval, selected],
  )
  

  useEffect(() => {
    if (!selected) {
      setSelected(timeInterval[0])
    }
  }, [activated, timeInterval, selected]);

  return (
    <Transition.Root show={activated} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center py-4 text-center sm:items-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className="relative transform rounded-lg border bg-white 
              px-4 mx-4 w-screen sm:w-1/3 pt-5 pb-4 text-left shadow-xl transition-all"
              >
                <div className="-ml-3 flex h-7 justify-end">
                  <button
                    type="button"
                    className="dark:text-dark-text-color text-light-text-color hover:text-gray-900 focus:outline-none"
                    onClick={() => handleClose()}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3">
                  <Dialog.Title as="h2" className="text-2xl font-bold">
                    Extend My Wait
                  </Dialog.Title>
                  <p className="mt-1">
                    You may only extend once.
                  </p>
                  <p className="my-4">
                    When will you arrive?
                  </p>
                  {handleTimeDropdown()}
                </div>
                <div className="mt-4 mb-5">
                  <button
                    className="bg-wait-time-button rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                    onClick={() => handleDelaySubmission()}
                  >
                    <span className="text-black text-lg font-bold inline">
                      Submit Delay
                    </span>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
