import { Item } from "../../../types/Item";
import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { updateDoc } from "firebase/firestore";

export default function OrderReviewItem({
  item,
  removeItem,
  orderInfoRef,
  orderInfo,
  orderCompleteScreen,
}: {
  item: any;
  removeItem?: Function;
  orderInfoRef: any;
  orderInfo: any;
  orderCompleteScreen?: boolean;
}) {
  const [currentQuantity, setCurrentQuantity] = useState(
    item?.quantity ? item.quantity : 1
  );

  const increment = async () => {
    if (currentQuantity !== null || currentQuantity !== undefined) {
      setCurrentQuantity(currentQuantity + 1);
    }
  };

  const decrement = async () => {
    if (currentQuantity > 0) {
      setCurrentQuantity(currentQuantity - 1);
    }
  };

  const updateItem = async () => {
    const currentOrderItems = orderInfo?.items;
    console.log(
      "🚀 ~ file: OrderReviewItem.tsx:35 ~ updateItem ~ currentOrderItems",
      currentOrderItems
    );

    if (currentQuantity === 0 && item && removeItem) {
      return removeItem(item);
    }

    if (currentOrderItems) {
      const foundItem = currentOrderItems.find((orderItem: Item) => {
        if (
          orderItem.name === item.name &&
          orderItem.modification === item.modification
        ) {
          if (item?.variants && item.variants !== orderItem.variants) {
            return undefined;
          }
          orderItem.quantity = orderItem.quantity ? currentQuantity : 1;
          return orderItem;
        }

        return null;
      });

      if (foundItem) {
        await updateDoc(orderInfoRef, {
          items: currentOrderItems,
        });
      }
    }
  };

  useEffect(() => {
    setCurrentQuantity(item.quantity ? item.quantity : 1);
  }, [item]);

  return (
    <div className={"flex flex-col py-4"}>
      <div className="flex justify-between">
        <div className=" max-w-xs">
          <p className="font-bold">
            {item.name} {item?.variant?.name ? `| ${item.variant.name} ` : ""}
          </p>
          {item?.temperature?.length ? (
            <p className="text-sm mt-1 w-40 break-words">
              Temp: {item.temperature}
            </p>
          ) : null}
          {item?.option?.length ? (
            <p className="text-sm mt-1 w-40 break-words">
              Option: {item.option} {item.extraOption ? `| ${item.extraOption}` : ""}
            </p>
          ) : null}
          {item?.addOn?.length ? (
            <p className="text-sm mt-1 w-40 break-words">+ {item.addOn}</p>
          ) : null}
          {item.modification ? (
            <p className="text-sm mt-1 w-40 break-words">{item.modification}</p>
          ) : null}
          {item.dippingSauce ? (
            <p className="text-sm mt-1 w-40 break-words">
              Dipping sauce: {item.dippingSauce}
            </p>
          ) : null}
          {item?.side && item?.side?.name ? (
            <p className="text-sm mt-1 w-40 break-words">
              Side: {item.side.name}{" "}
              {item.side.upcharge === 0 ? "" : `+$${item.side.upcharge}`}
            </p>
          ) : null}
          {item?.extraSide && item?.extraSide?.name ? (
            <p className="text-sm mt-1 w-40 break-words">
              2nd Side: {item.extraSide.name}{" "}
              {item.extraSide.upcharge === 0 ? "" : `+$${item.extraSide.upcharge}`}
            </p>
          ) : null}
        </div>

        <div className="flex flex-col">
          <div className="flex">
            {orderInfo?.submitted || orderCompleteScreen ? (
              <span className="font-bold">x{currentQuantity}</span>
            ) : (
              <>
                <button className="outline-none" onClick={() => decrement()}>
                  <MinusSmIcon className="h-8 w-8 mr-4 mt-0.5" />
                </button>
                <span className="font-bold mt-1.5">{currentQuantity}</span>
                <button className="outline-none" onClick={() => increment()}>
                  <PlusSmIcon className="h-8 w-8 ml-4 mt-0.5" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-6">
        <p className="py-1 mt-1">{item.price || item?.name === "Water" ? '$' + item.price : "TBD"}</p>
        {item.quantity !== currentQuantity ? (
          <>
            <button
              onClick={() => updateItem()}
              className=" bg-wait-time-button m-auto rounded-3xl font-semibold px-2 py-1 col-end-8 col-span-1 float-right mr-4"
            >
              Update
            </button>
            <p className="col-start-1 col-end-6">&nbsp;</p>
          </>
        ) : null}
      </div>
    </div>
  );
}
