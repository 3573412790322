import React, { useEffect, useState } from "react";
import QuantityInput from "../../Components/QuantityInput";
import TextInput from "../../Components/TextInput";
import { isValidPhoneNumber, PhoneNumberMatcher } from "libphonenumber-js";

export default function PartySubmission({
  partyQuantity,
  setPartyQuantity,
  handleSubmit,
  firstName,
  lastName,
  phoneNumber,
  formIsValid,
  handleChange,
  note,
  showSpinner,
  setShowSpinner,
}: {
  partyQuantity: number;
  setPartyQuantity: Function;
  handleSubmit: Function;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  formIsValid: boolean | null;
  handleChange: Function;
  note: string;
  showSpinner: boolean;
  setShowSpinner: Function;
}) {
  const [valid, setValid] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);


  useEffect(() => {
    if (
      partyQuantity &&
      firstName &&
      lastName &&
      isValidPhoneNumber(phoneNumber.replaceAll("-", ""), "US")
    ) {
      return setValid(true);
    }

    return setValid(false);
  }, [partyQuantity, firstName, lastName, phoneNumber]);

  // on android devices push the screen up when the keyboard is open
  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 1;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const runValidation = (field: string) => {
    console.log(
      "🚀 ~ file: PartySubmission.tsx:64 ~ runValidation ~ field",
      field
    );
    switch (field) {
      case "phoneNumber":
        if (isValidPhoneNumber(phoneNumber, "US")) {
          return setPhoneNumberValid(true);
        }

        return setPhoneNumberValid(false);
      case "firstName":
        if (firstName.length > 0) {
          return setFirstNameValid(true);
        }

        return setFirstNameValid(false);
      case "lastName":
        if (lastName.length > 0) {
          return setLastNameValid(true);
        }

        return setLastNameValid(false);

      default:
        break;
    }
  };

  return (
    <>
      <div className="flex flex-col w-full my-1">
        <span>How many people in your party?</span>
        <QuantityInput
          quantity={partyQuantity}
          setQuantity={setPartyQuantity}
        />
      </div>
      <TextInput
        name="firstName"
        id="firstName"
        label="First Name"
        type="text"
        value={firstName}
        valid={firstNameValid}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e, "firstName")
        }
        placeholder={"Jane"}
        errorMessage={"First name is empty"}
        onBlur={() => runValidation("firstName")}
      />
      <TextInput
        name="lastName"
        id="lastName"
        label="Last Name"
        type="text"
        value={lastName}
        valid={lastNameValid}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e, "lastName")
        }
        placeholder={"Doe"}
        errorMessage={"Last name is empty"}
        onBlur={() => runValidation("lastName")}
      />
      <TextInput
        name="phoneNumber"
        id="phoneNumber"
        label="Phone Number"
        type="tel"
        value={phoneNumber}
        valid={phoneNumberValid}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e, "phoneNumber")
        }
        placeholder={"(555) 555-5555"}
        errorMessage={"Phone number is invalid or empty"}
        onBlur={() => runValidation("phoneNumber")}
      />
      <div className="flex flex-col w-full pt-4">
        <label htmlFor="modification">Anything we should know about? (optional)</label>
        <textarea
          id="modification"
          className="bg-[#F2F2F2] rounded-md p-2 mt-2 resize-none h-32"
          placeholder="&bull; Need kid's menus or high chairs?&#10;&bull; Celebrating something?&#10;&bull; Need accessibility accommodations?"
          value={note}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange(e, "note")
          }
        />
      </div>
      <button
        className="bg-wait-time-button flex justify-center rounded-3xl py-3 w-full text-center mt-3 outline-none disabled:bg-eatout-light-gray"
        onClick={() => handleSubmit()}
        disabled={!valid || showSpinner}
      >
        {showSpinner ? (
          <>
            <span className="text-black text-lg font-bold inline">
              Submitting
            </span>
            <svg className="animate-spin h-5 w-5 ml-3 mt-1" viewBox="0 0 24 24">
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </>
        ) : (
          <span className="text-black text-lg font-bold inline">Submit</span>
        )}
      </button>
    </>
  );
}
