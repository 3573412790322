import { useState } from "react";
import WaitListSubmission from "./WaitListSubmission";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import PhoneNumExists from "../../Components/modals/PhoneNumExists";
import { AsYouType } from "libphonenumber-js";

export default function WaitListContainer() {
  const functions = useFunctions();
  let navigate = useNavigate();
  const [partyQuantity, setPartyQuantity] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formIsValid, setFormIsValid] = useState<boolean | null>(true);
  const [note, setNote] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [phoneNumModal, setPhoneNumModal] = useState(false);
  const [orderLink, setOrderLink] = useState("");

  const { restaurantId } = useParams();

  const submitPartyRequestEndpoint = httpsCallable(
    functions,
    "generalFunctions-submitPartyRequest"
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const newValue = e.target.value;
    switch (type) {
      case "firstName":
        setFirstName(newValue);
        break;
      case "lastName":
        setLastName(newValue);
        break;
      case "phoneNumber":
        const value = newValue;
        // formatting the phone number
        if (/(.?\d){4,}/.test(value)) {
          setPhoneNumber(new AsYouType("US").input(value));
        } else setPhoneNumber(value);
        break;
      case "note":
        setNote(newValue);
        break;
      default:
        break;
    }
  };

  const handleResetForm = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setFormIsValid(null);
    setPartyQuantity(1);
    handleClose();
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    setShowSpinner(true);
    setOrderLink("");
    const {
      data: { status, error, orderLink, orderId },
    } = (await submitPartyRequestEndpoint({
      restaurantId,
      firstName,
      lastName,
      phoneNumber: phoneNumber.replace(/[-+()\s]/g, "").trim(),
      partyQuantity,
      note,
      timeOfQuote: DateTime.local().valueOf(),
      updatedAt: DateTime.local().valueOf(),
    })) as unknown as {
      data: {
        status: boolean;
        error: string;
        orderLink?: string;
        orderId?: string;
      };
    };
    console.log("status and erorr", status, error);
    if (!status && error === "Phone number already exists") {
      setShowSpinner(false);
      if (orderLink) { 
        setOrderLink(orderLink);
      }
      setPhoneNumModal(true);
      return false;
    }
    if (status && !error && orderId) {
      setShowSpinner(false);
      navigate(`/${restaurantId}/order/${orderId}`);
      return true;
    }
    setShowSpinner(false);
    return false;
  };

  return (
    <>
      <PhoneNumExists
        activated={phoneNumModal}
        setActivated={setPhoneNumModal}
        orderLink={orderLink}
      />

      <WaitListSubmission
        handleResetForm={handleResetForm}
        handleSubmit={handleSubmit}
        partyQuantity={partyQuantity}
        setPartyQuantity={setPartyQuantity}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        note={note}
        handleChange={handleChange}
        formIsValid={formIsValid}
        showSpinner={showSpinner}
        setShowSpinner={setShowSpinner}
      />
    </>
  );
}
