interface Props {
    title: string;
    subTitle?: string;
    options: string[];
    isOption?: boolean;
    selectedOption: any;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const OptionsDropdown = ({
    title,
    subTitle,
    options,
    selectedOption,
    onChange,
    isOption = true,
}: Props) => {
    const priceDisplay = (option: any) => {
        if (option?.upcharge === 0) return "";

        if (option?.upcharge) {
            return `: +$${option.upcharge}`;
        } else if (option?.price) {
            return `: $${option.price}`;
        } else {
            return "";
        }
    };

    return (
        <>
            <h2 className={`font-bold text-lg ${subTitle ? "mt-5" : "my-5"}`}>
                {title}
            </h2>
            {subTitle ? <p className="mb-5">{subTitle}</p> : null}
            <select
                value={
                    typeof selectedOption === "string"
                        ? selectedOption
                        : selectedOption?.name ? JSON.stringify(selectedOption) : ''
                }
                onChange={onChange}
                placeholder={title}
                className="w-full p-2 text-gray-900 bg-gray-100 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-eatout-green focus:border-transparent"
            >
                <option value="" disabled>
                    {title}
                </option>
                {options.map((option: any) => (
                    <option
                        key={isOption ? option : JSON.stringify(option)}
                        value={isOption ? option : JSON.stringify(option)}
                    >
                        {isOption
                            ? option.replaceAll("-", " ")
                            : `${option.name}${priceDisplay(option)}`}
                    </option>
                ))}
            </select>
        </>
    );
};
