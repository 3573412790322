import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
} from "reactfire";
import Home from "./routes/Home/Home";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import PageNotFound from "./routes/404/PageNotFound";
import RestaurantDetail from "./routes/Restaurant/RestaurantDetail";
import OrderScreen from "./routes/Order/OrderScreen";
import "react-toastify/dist/ReactToastify.css";
import OrderReviewScreen from "./routes/Order/OrderReviewScreen";
import OrderConfirmation from "./routes/Order/OrderConfirmation";
import OrderComplete from "./routes/Order/OrderComplete";
import OrderLandingPage from "./routes/Order/OrderLandingPage";
import WaitListContainer from "./routes/Restaurant/WaitListContainer";

const MainRoutes = () => {
  const router = createBrowserRouter([
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "restaurant/:restaurantId",
      element: <RestaurantDetail />,
    },
    {
      path: "restaurant/:restaurantId/join",
      element: <WaitListContainer />,
    },
    {
      path: ":restaurantId/order/:orderId",
      element: <OrderLandingPage />,
    },
    {
      path: ":restaurantId/order/:orderId/order",
      element: <OrderScreen />,
    },
    {
      path: ":restaurantId/order/:orderId/review",
      element: <OrderReviewScreen />,
    },
    {
      path: ":restaurantId/order/:orderId/confirmation",
      element: <OrderConfirmation />,
    },
    {
      path: ":restaurantId/order/:orderId/completed",
      element: <OrderComplete />,
    },
  ]);

  return (
    <div className="bg-main-background overflow-x-hidden">
      <ToastContainer limit={1} />
      <RouterProvider router={router} />
    </div>
  );
};

function App() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  // const auth = getAuth(useFirebaseApp());
  // const storage = getStorage(useFirebaseApp());
  const functions = getFunctions(useFirebaseApp());

  if (process.env.NODE_ENV === "development") {
    console.log(
      "🚀 ~ file: App.tsx:112 ~ App ~ process.env.NODE_ENV",
      process.env.NODE_ENV
    );
    // Set up emulators
    // connectFirestoreEmulator(firestoreInstance, "localhost", 8080);
    // connectFunctionsEmulator(functions, "localhost", 5001);
    // connectStorageEmulator(storage, "localhost", 9199);
    // connectAuthEmulator(auth, "http://localhost:9099");
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <FunctionsProvider sdk={functions}>
        <MainRoutes />
      </FunctionsProvider>
    </FirestoreProvider>
  );
}

export default App;
