import HomeHeader from "./Header";
import ResturauntList from "./RestaurantList";

export default function Home() {
  return (
    <div className="mx-auto max-w-xl overflow-x-hidden">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
      <div className="mx-auto max-w-xl overflow-x-hidden">
        <HomeHeader />
        {/* <CuisineSlider /> */}
        <ResturauntList />
      </div>
    </div>
  );
}
