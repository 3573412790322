import { ClockIcon } from "@heroicons/react/solid";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { ScrollRestoration, useNavigate, useParams } from "react-router-dom";
import { useFirestoreDocData } from "reactfire";
import { DateTime, Duration } from "luxon";
import DelaySeatingModal from "../../Components/modals/DelaySeating";
import WaitTimeConfirmation from "../../Components/modals/WaitTimeConfirmation";
import { LoadingSpinner } from "../../Components/Spinner";
import HowThisWorks from "../../Components/modals/HowThisWorks";
import CancelConfirmation from "../../Components/modals/CancelConfirmation";
import { Switch } from "@headlessui/react";
import { grabFirstName } from "../../common";
import TableIsReady from "../../Components/modals/TableIsReady";
import Party from "../../assets/svgs/Party.svg";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function getIntervals(timeInMilliseconds: number) {
  if (!timeInMilliseconds) {
    return [];
  }

  const startTime = DateTime.fromMillis(timeInMilliseconds);
  const currentTime = startTime.startOf("hour").plus({ minutes: 30 });
  const intervals: DateTime[] = [];

  for (let i = 0; i < 5; i++) {
    const newTimeInterval = currentTime.plus({ minutes: 15 * i });
    if (newTimeInterval.valueOf() > startTime.valueOf()) {
      intervals.push(newTimeInterval);
    }
  }

  return intervals;
}

function OrderLandingPage() {
  const navigate = useNavigate();
  const { restaurantId, orderId } = useParams();
  const [quotedWaitTime, setQuotedWaitTime] = useState("");
  const [openDelayModal, setOpenDelayModal] = useState(false);
  const [openHowModal, setOpenHowModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openTableIsReadyModal, setOpenTableIsReadyModal] = useState(false);
  const [openWaitTimeConfirmationModal, setOpenWaitTimeConfirmationModal] =
    useState(false);
  const db = getFirestore();
  const restaurantInfoRef = doc(db, `Restaurants/${restaurantId}`);
  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);
  const { status, data: restaurantInfoDetail } = useFirestoreDocData(
    restaurantInfoRef,
    {
      idField: "id",
    }
  );
  const {
    status: orderInfoStatus,
    data: orderInfo,
    isComplete,
  } = useFirestoreDocData(orderInfoRef, {
    idField: "id",
  });

  const milliSecondTimeConversion = useCallback(
    (grabValue?: boolean) => {
      if (!orderInfo?.timeOfQuote) {
        return 0;
      }

      // Convert to hours and minutes
      const duration = Duration.fromMillis(
        orderInfo?.delaySubmitted
          ? orderInfo?.delaySubmitted - orderInfo?.timeOfQuote
          : orderInfo?.quotedWait - orderInfo?.timeOfQuote
      );

      // Get the current date and time
      const now = DateTime.fromMillis(orderInfo?.timeOfQuote);

      // Add the time object to the current date and time
      const result = now.plus(duration).valueOf();
      if (!grabValue) {
        setQuotedWaitTime(now.plus(duration).toFormat("hh:mm a"));
      }

      return result;
    },
    [orderInfo?.quotedWait, orderInfo?.delaySubmitted, orderInfo?.timeOfQuote]
  );

  const EditOrder = () => {
    navigate(`/${restaurantId}/order/${orderId}/review`);
  };

  const notifyHere = (status: boolean) => {
    updateDoc(orderInfoRef, {
      customerIsHere: status,
    });
  };

  useEffect(() => {
    if (orderInfo?.quotedWait && orderInfo?.status === "unconfirmed") {
      setOpenWaitTimeConfirmationModal(true);
    } else {
      milliSecondTimeConversion();
    }
  }, [
    orderInfo?.quotedWait,
    orderInfo?.status,
    orderInfo?.delaySubmitted,
    milliSecondTimeConversion,
  ]);

  useEffect(() => {
    if (orderInfo?.status === "notified" && !orderInfo?.customerConfirmed) {
      setOpenTableIsReadyModal(true);
    }
  }, [orderInfo?.status]);

  useEffect(() => {
    if (
      (orderInfoStatus === "success" || orderInfoStatus === "error") &&
      !orderInfo
    ) {
      navigate("/");
    }
  }, [orderInfo, isComplete, orderInfoStatus, navigate]);

  useEffect(() => {
    if (orderInfo?.customerCanceled) {
      navigate("/");
      return () => {};
    }
    if (orderInfo?.status === "notified" && orderInfo?.customerConfirmed) {
      navigate(`/${restaurantId}/order/${orderId}/completed`);
      return () => {};
    }

    if (orderInfo?.status === "deleted" && orderInfo?.reasonForDecline) {
      navigate(`/`);
      return () => {};
    }
  }, [
    orderInfo?.status,
    navigate,
    restaurantId,
    orderId,
    orderInfo?.reasonForDecline,
    orderInfo?.customerCanceled,
  ]);

  return (
    <>
      <TableIsReady
        activated={openTableIsReadyModal}
        setActivated={setOpenTableIsReadyModal}
      />
      <HowThisWorks activated={openHowModal} setActivated={setOpenHowModal} />
      {getIntervals(milliSecondTimeConversion(true)) ? (
        <DelaySeatingModal
          activated={openDelayModal}
          setActivated={setOpenDelayModal}
          timeInterval={getIntervals(milliSecondTimeConversion(true))}
          timeOfQuote={orderInfo?.timeOfQuote}
        />
      ) : null}
      <WaitTimeConfirmation
        activated={openWaitTimeConfirmationModal}
        setActivated={setOpenWaitTimeConfirmationModal}
        quotedWait={orderInfo?.quotedWait}
        timeOfQuote={orderInfo?.timeOfQuote}
      />
      <CancelConfirmation
        activated={openCancelModal}
        setActivated={setOpenCancelModal}
        orderInfo={orderInfo}
      />
      {status === "loading" || orderInfoStatus === "loading" ? (
        <LoadingSpinner />
      ) : (
        <div className="mx-auto max-w-3xl bg-white pb-16">
          <div
            className="h-16 bg-center w-auto bg-cover"
            style={{
              backgroundImage: `url('${restaurantInfoDetail?.mainImage}')`,
            }}
          />
          <div className="px-5 mb-2">
            <h1 className="font-bold text-2xl mt-4 mb-2 text-center">
              Welcome to {restaurantInfoDetail?.name},{" "}
              {grabFirstName(orderInfo?.name)}
            </h1>
            <div className="flex flex-col pb-2">
              {orderInfo?.submitted ? (
                <div className="flex flex-col mt-2 bg-eatout-green bg-opacity-10 pt-4 px-4 w-auto mb-4">
                  <div className="flex justify-center">
                    {orderInfo?.printed ? (
                      <p className=" text-black font-bold text-2xl text-center">
                        We've started working <br/>on your order
                      </p>
                    ) : (
                      <>
                        <img
                          className="h-5 w-5 mt-1 mr-2"
                          src={Party}
                          alt="Party"
                        />
                        <p className=" text-black font-bold text-xl text-center">
                          Order submitted!
                        </p>
                      </>
                    )}
                  </div>
                  {orderInfo?.printed ? (
                    <span className="text-sm text-center mt-2">
                      If you would like to make changes to your
                      order please contact the host.
                    </span>
                  ) : (
                    <span className="text-sm text-center mt-2">
                      We'll start preparing your order when your table is ready.
                    </span>
                  )}

                  <div
                    className="underline m-auto font-bold rounded-3xl py-2.5 w-full text-center my-4 outline-none disabled:bg-gray-500 disabled:text-white"
                    onClick={() => {
                      if (orderInfo?.submitted) {
                        EditOrder();
                      } else {
                        navigate(`/${restaurantId}/order/${orderId}/order`);
                      }
                    }}
                  >
                    {orderInfo?.status === "seated" ||
                    orderInfo?.status === "notified" ||
                    orderInfo?.printed
                      ? "View your order"
                      : "View and Edit your order"}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col mt-2 bg-[#F7F7F7] py-4 px-4 w-auto mb-4">
                  <p className=" text-black font-bold text-xl text-center">
                    Save time! Build your order, while you wait
                  </p>
                  <span className="text-sm text-center mt-2">
                    We'll start preparing your order when your table is ready.
                  </span>
                  <button
                    className="bg-wait-time-button m-auto font-bold rounded-3xl py-2.5 w-full text-center my-4 text-lg outline-none disabled:bg-gray-500 disabled:text-white"
                    onClick={() => {
                      if (orderInfo?.submitted) {
                        EditOrder();
                      } else {
                        navigate(`/${restaurantId}/order/${orderId}/order`);
                      }
                    }}
                  >
                    {orderInfo?.submitted ? "Edit Order" : "Start your order"}
                  </button>
                  <div className="flex flex-row justify-center">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1"
                    >
                      <path
                        d="M9 7H11V5H9M10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z"
                        fill="black"
                      />
                    </svg>
                    <button
                      className="ml-3 outline-none flex flex-col justify-center underline font-bold"
                      onClick={() => setOpenHowModal(true)}
                    >
                      Learn how this works
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="bg-[#F7F7F7] py-4 w-auto flex flex-col mb-6">
              <div className="mt-1">
                <div className="flex flex-row justify-center">
                  <ClockIcon
                    height={24}
                    width={24}
                    className={"inline mt-0.5"}
                  />
                  <span className="text-lg inline ml-2 text-center">
                    Estimated Seating
                  </span>
                </div>
                <div className="ml-1 text-center mt-2">
                  {orderInfo?.quotedWait ? (
                    <>
                      <span className="font-bold text-2xl inline">
                        {quotedWaitTime}
                      </span>
                    </>
                  ) : (
                    <span className="font-bold text-2xl inline mt-2">
                      Pending
                    </span>
                  )}
                </div>
                <div className=" flex flex-row mt-4 mx-8 justify-between">
                  <p
                    className={` ${
                      !orderInfo?.customerIsHere ? "font-bold" : ""
                    }`}
                  >
                    On my way
                  </p>
                  <Switch
                    checked={orderInfo?.customerIsHere}
                    onChange={() => notifyHere(!orderInfo?.customerIsHere)}
                    className={classNames(
                      orderInfo?.customerIsHere
                        ? "bg-wait-time-button"
                        : "bg-gray-200",
                      "relative inline-flex h-7 w-16 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      className={classNames(
                        orderInfo?.customerIsHere
                          ? "translate-x-9"
                          : "translate-x-0",
                        "pointer-events-none relative inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    >
                      <span
                        className={classNames(
                          orderInfo?.customerIsHere
                            ? "opacity-0 ease-out duration-100"
                            : "opacity-100 ease-in duration-200",
                          "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true"
                      >
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8.5" cy="8.5" r="8.5" fill="white" />
                        </svg>
                      </span>
                      <span
                        className={classNames(
                          orderInfo?.customerIsHere
                            ? "opacity-100 ease-in duration-200"
                            : "opacity-0 ease-out duration-100",
                          "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true"
                      >
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8.5" cy="8.5" r="8.5" fill="white" />
                        </svg>
                      </span>
                    </span>
                  </Switch>
                  <p
                    className={` ${
                      orderInfo?.customerIsHere ? "font-bold" : ""
                    }`}
                  >
                    I'm here
                  </p>
                </div>
                <div className="flex flex-row justify-between mx-2 mt-4">
                  {getIntervals(
                    milliSecondTimeConversion(true)
                      ? milliSecondTimeConversion(true)
                      : 0
                  )?.length &&
                  !orderInfo?.delaySubmitted &&
                  !orderInfo?.addedByHost ? (
                    <button
                      onClick={() => setOpenDelayModal(true)}
                      className={
                        "bg-white m-auto font-bold rounded-sm py-3 w-48 mt-4 mb-5 outline-none text-black flex flex-row justify-center mr-2"
                      }
                    >
                      Delay seating
                    </button>
                  ) : null}
                  <button
                    className={`bg-white m-auto font-bold rounded-sm py-3 ${
                      orderInfo?.delaySubmitted ||
                      orderInfo?.addedByHost ||
                      !getIntervals(
                        milliSecondTimeConversion(true)
                          ? milliSecondTimeConversion(true)
                          : 0
                      )?.length
                        ? "w-full"
                        : "w-48 ml-2"
                    }  mt-4 mb-5 outline-none text-black flex flex-row justify-center`}
                    onClick={() => setOpenCancelModal(true)}
                  >
                    <span className="ml-3 text-center">Leave waitlist</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-[#F7F7F7] py-4 w-auto rounded-md flex flex-col">
              <div className="mt-1">
                <div className="flex flex-col justify-center px-3">
                  <p className="text-sm mb-1 font-bold flex w-fit rounded-md">
                    Have more questions?
                  </p>
                  <p className="inline text-left text-sm">
                    Respond to the text we sent you anytime during your wait!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ScrollRestoration />
    </>
  );
}

export default OrderLandingPage;
