import { Transition, Dialog } from "@headlessui/react";
import {
  Fragment,
  useRef
} from "react";
import { useNavigate } from "react-router-dom";

function WaitlistDecline({
  activated,
  setActivated,
  reasonForDecline,
  restaurantPhoneNumber
}: {
  activated: boolean;
  setActivated: Function;
  reasonForDecline: string;
  restaurantPhoneNumber: string;
}) {
  const navigate = useNavigate();

  const cancelButtonRef = useRef(null);

  const handleCancel = async () => {
    setActivated(false);
    setActivated(false);
    navigate("/");
  };

  return (
    <Transition.Root show={activated} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => false}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className="relative transform rounded-lg border bg-white 
                px-4 pt-5 pb-4 text-left shadow-xl transition-all"
              >
                <div className="mt-3">
                  <Dialog.Title as="h2" className="text-2xl font-bold text-center pb-2">
                    Request Status
                  </Dialog.Title>
                  <p className="mt-1 py-4">
                    Unfortunately, we could not add you to the waitlist.
                  </p>
                  <p className="mt-1 font-bold inline">
                    Reason:
                  </p>
                  &nbsp;<p className="inline">{reasonForDecline}</p><br/>
                  <p className="pt-6 inline">For further questions please call: </p>
                  <a href={`tel:${restaurantPhoneNumber}`} className="font-bold inline">{restaurantPhoneNumber}</a>
                </div>
                <div className="mt-2 mb-5">
                  <button
                    className="bg-gray rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                    onClick={() => handleCancel()}
                  >
                    <span className="text-black text-lg font-bold inline">
                      Close
                    </span>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default WaitlistDecline;
