import { useFirestoreDocData } from "reactfire";
import { getFirestore, doc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { LoadingSpinner } from "../../Components/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import ItemsTab from "../../Components/ItemsTab";
import DelaySeatingModal from "../../Components/modals/DelaySeating";
import SearchInput from "../../Components/SearchInput";
import { DateTime, Duration } from "luxon";
import WaitTimeConfirmation from "../../Components/modals/WaitTimeConfirmation";
import WaitlistDecline from "../../Components/modals/WaitlistDecline";
import { Item } from "../../../types/Item";
import TableIsReady from "../../Components/modals/TableIsReady";
import { ArrowLeftIcon } from "@heroicons/react/solid";

function getIntervals(timeInMilliseconds: number) {
  if (!timeInMilliseconds) {
    return [];
  }

  const startTime = DateTime.fromMillis(timeInMilliseconds);
  const currentTime = startTime.startOf("hour").plus({ minutes: 30 });
  const intervals: DateTime[] = [];

  for (let i = 0; i < 5; i++) {
    const newTimeInterval = currentTime.plus({ minutes: 15 * i });
    if (newTimeInterval.valueOf() > startTime.valueOf()) {
      intervals.push(newTimeInterval);
    }
  }

  return intervals;
}

export default function RestaurantDetail() {
  const navigate = useNavigate();
  const { restaurantId, orderId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [openWaitlistDeclineModal, setOpenWaitlistDeclineModal] =
    useState(false);
  const [openWaitTimeConfirmationModal, setOpenWaitTimeConfirmationModal] =
    useState(false);
  const [openTableIsReadyModal, setOpenTableIsReadyModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  const db = getFirestore();
  const restaurantInfoRef = doc(db, `Restaurants/${restaurantId}`);
  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);
  const { status, data: restaurantInfoDetail } = useFirestoreDocData(
    restaurantInfoRef,
    {
      idField: "id",
    }
  );
  const generatedMenuRef = doc(
    db,
    `Restaurants/${restaurantId}/Menu/GeneratedMenu`
  );
  const { status: generatedMenuStatus, data: generatedMenu } =
    useFirestoreDocData(generatedMenuRef);
  const {
    status: orderInfoStatus,
    data: orderInfo,
    isComplete,
  } = useFirestoreDocData(orderInfoRef, {
    idField: "id",
  });

  const milliSecondTimeConversion = useCallback(
    (grabValue?: boolean) => {
      if (!orderInfo?.timeOfQuote) {
        return 0;
      }

      // Convert to hours and minutes
      const duration = Duration.fromMillis(
        orderInfo?.delaySubmitted
          ? orderInfo?.delaySubmitted - orderInfo?.timeOfQuote
          : orderInfo?.quotedWait - orderInfo?.timeOfQuote
      );

      // Get the current date and time
      const now = DateTime.fromMillis(orderInfo?.timeOfQuote);

      // Add the time object to the current date and time
      const result = now.plus(duration).valueOf();

      return result;
    },
    [orderInfo?.quotedWait, orderInfo?.delaySubmitted, orderInfo?.timeOfQuote]
  );

  useEffect(() => {
    if (orderInfo?.quotedWait && orderInfo?.status === "unconfirmed") {
      setOpenWaitTimeConfirmationModal(true);
    } else {
      milliSecondTimeConversion();
    }
  }, [
    orderInfo?.quotedWait,
    orderInfo?.status,
    orderInfo?.delaySubmitted,
    milliSecondTimeConversion,
  ]);

  useEffect(() => {
    if (orderInfo?.status === "notified" && !!orderInfo?.customerConfirmed) {
      setOpenTableIsReadyModal(true);
    }
  }, [orderInfo?.status]);

  useEffect(() => {
    if (
      (orderInfoStatus === "success" || orderInfoStatus === "error") &&
      !orderInfo
    ) {
      navigate("/");
    }
  }, [orderInfo, isComplete, orderInfoStatus, navigate]);

  useEffect(() => {
    if (orderInfo?.customerCanceled) {
      navigate("/");
      return () => {};
    }
    if (orderInfo?.status === "notified" && orderInfo?.customerConfirmed) {
      navigate(`/${restaurantId}/order/${orderId}/completed`);
      return () => {};
    }

    if (orderInfo?.status === "deleted" && orderInfo?.reasonForDecline) {
      navigate(`/`);
      return () => {};
    }
  }, [
    orderInfo?.status,
    navigate,
    restaurantId,
    orderId,
    orderInfo?.reasonForDecline,
    orderInfo?.customerCanceled
  ]);

  useEffect(() => {
    if (orderInfo?.reasonForDecline?.length) {
      setOpenWaitlistDeclineModal(true);
    }
  }, [orderInfo?.reasonForDecline]);

  // create a function that adds all the quantities of the items in the cart
  const getTotalQuantity = useCallback(() => {
    let totalQuantity = 0;
    if (orderInfo?.items) {
      orderInfo.items.forEach((item: Item) => {
        totalQuantity += item?.quantity ? item.quantity : 0;
      });
    }
    return totalQuantity;
  }, [orderInfo?.items]);

  return (
    <div>
      {status === "loading" || orderInfoStatus === "loading" ? (
        <LoadingSpinner />
      ) : (
        <>
          {getIntervals(milliSecondTimeConversion(true)) ? (
            <DelaySeatingModal
              activated={openModal}
              setActivated={setOpenModal}
              timeInterval={getIntervals(milliSecondTimeConversion(true))}
              timeOfQuote={orderInfo?.timeOfQuote}
            />
          ) : null}
          <WaitlistDecline
            activated={openWaitlistDeclineModal}
            setActivated={setOpenWaitlistDeclineModal}
            reasonForDecline={orderInfo?.reasonForDecline}
            restaurantPhoneNumber={restaurantInfoDetail?.phoneNumber}
          />

          <WaitTimeConfirmation
            activated={openWaitTimeConfirmationModal}
            setActivated={setOpenWaitTimeConfirmationModal}
            quotedWait={orderInfo?.quotedWait}
            timeOfQuote={orderInfo?.timeOfQuote}
          />
          <TableIsReady
            activated={openTableIsReadyModal}
            setActivated={setOpenTableIsReadyModal}
          />
          <div className="mx-auto max-w-3xl bg-white overscroll-contain">
            <>
              <div
                className="h-12 bg-center w-auto bg-cover overflow-y-hidden"
                style={{
                  backgroundImage: `url('${restaurantInfoDetail?.mainImage}')`,
                }}
              />
              <div className="flex justify-between h-12 px-5">
                <button
                  className="mt-2"
                  onClick={() => navigate(`/${restaurantId}/order/${orderId}`)}
                >
                  <ArrowLeftIcon
                    height={30}
                    width={30}
                    className=" text-light-text-color mt-3 text-black"
                    aria-hidden="true"
                  />
                </button>
                <h1 className="text-green-text-title font-bold text-3xl my-4">
                  {restaurantInfoDetail?.name}
                </h1>
              </div>
              <div className="px-5 mb-2">
                <SearchInput
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </>
            <div className="overscroll-contain overflow-y-hidden">
              {generatedMenuStatus === "loading" ? (
                <div role="status" className="flex justify-center">
                  <svg
                    aria-hidden="true"
                    className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <ItemsTab
                  orderScreen={true}
                  searchText={searchText}
                  menu={generatedMenu}
                  orderInfoItems={orderInfo?.items}
                />
              )}
              {orderInfo?.items?.length ? (
                <>
                  <div className="border rounded-3xl mx-9 max-w-xl h-16 m-auto fixed inset-x-0 bottom-[1.8rem] backdrop-blur-sm" />
                  <button
                    className="bg-wait-time-button m-auto font-bold rounded-3xl py-2 w-3/4 max-w-xl text-center outline-none disabled:bg-eatout-light-gray fixed inset-x-0 bottom-10"
                    onClick={() =>
                      navigate(`/${restaurantId}/order/${orderId}/review`)
                    }
                  >
                    View Order ({getTotalQuantity()})
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
