import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";

function CancelConfirmation({
  activated,
  setActivated,
  orderInfo,
}: {
  activated: boolean;
  setActivated: Function;
  orderInfo?: any;
}) {
  const { restaurantId, orderId } = useParams();
  const db = getFirestore();
  const navigate = useNavigate();

  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);

  const handleCancel = async () => {
    const payload: any = {};

    if (orderInfo?.status === "requested") {
      payload["customerCanceled"] = "true";
      payload["status"] = "deleted";
    } else {
      payload["customerCanceled"] = "true";
    }

    await updateDoc(orderInfoRef, payload);
    setActivated(false);
    navigate("/");
  };

  return (
    <Transition.Root show={activated} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setActivated(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className="relative transform rounded-lg border bg-white 
                px-6 pt-5 pb-4 text-left shadow-xl transition-all"
              >
                <div className="-ml-3 flex h-7 justify-end">
                  <button
                    type="button"
                    className="dark:text-dark-text-color text-light-text-color hover:text-gray-900 focus:outline-none"
                    onClick={() => setActivated(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
                <>
                  <div className="mt-3">
                    <Dialog.Title
                      as="h2"
                      className="text-2xl font-bold whitespace-pre text-center"
                    >
                      Cancel Request
                    </Dialog.Title>
                  </div>
                  <p className="mt-2">
                    Are you sure you want to cancel your waitlist request?
                  </p>
                  <div className="mt-6 mb-5">
                    <button
                      className="bg-wait-time-button rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                      onClick={() => handleCancel()}
                    >
                      <span className="text-black text-lg font-bold inline">
                        Yes
                      </span>
                    </button>
                  </div>
                  <div className="mt-2 mb-5">
                    <button
                      className="bg-gray rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                      onClick={() => {
                        setActivated(false);
                      }}
                    >
                      <span className="text-black text-lg font-bold inline">
                        No
                      </span>
                    </button>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CancelConfirmation;
