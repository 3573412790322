import { Transition, Dialog } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function PhoneNumExists({
  activated,
  setActivated,
  orderLink,
}: {
  activated: boolean;
  setActivated: Function;
  orderLink: string;
}) {
  const { restaurantId } = useParams();

  const navigate = useNavigate();

  const handleOrderLink = () => {
    setActivated(false);
    // url decode orderLink
    console.log("🚀 ~ file: PhoneNumExists.tsx:23 ~ handleOrderLink ~ orderLink", orderLink)
    navigate(`/${restaurantId}/${decodeURIComponent(orderLink.trim())}`);
  };

  return (
    <Transition.Root show={activated} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => false}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className="relative transform rounded-lg border bg-white 
                px-8 pt-5 pb-4 text-left shadow-xl transition-all max-w-lg"
              >
                <div className="mt-3">
                  <Dialog.Title as="h2" className="text-2xl font-bold">
                    It looks like you're already on the waitlist
                  </Dialog.Title>
                </div>
                <p className="mt-2">
                  Click the button below to view your order.
                </p>
                <div className="mt-6 mb-5">
                  <button
                    className="bg-wait-time-button rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                    onClick={() => handleOrderLink()}
                  >
                    <span className="text-black text-lg font-bold inline">
                      View Order
                    </span>
                  </button>
                  <button
                    className="bg-transparent mt-2 rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                    onClick={() => setActivated(false)}
                  >
                    <span className="text-black text-lg font-bold inline">
                      Close
                    </span>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PhoneNumExists;
