import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from "reactfire";
import { collection, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { LoadingSpinner } from "../../Components/Spinner";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";

const RestaurantCard = ({
  restaurant,
  navigate,
}: {
  restaurant: any;
  navigate: any;
}) => {
  const functions = useFunctions();
  const isRestaurantOpenEndpoint = httpsCallable(functions, "generalFunctions-isRestaurantOpen");
  const [restaurantIsOpen, setRestaurantIsOpen] = useState<any>(true);

  // call isRestaurantOpen function
  const isRestaurantOpen = async () => {
    const { data } = await isRestaurantOpenEndpoint({
      restaurantId: restaurant.id,
    });
    setRestaurantIsOpen(data);
  };

  useEffect(() => {
    if (restaurant.id) {
      isRestaurantOpen();
    }
  }, [restaurant.id, restaurantIsOpen]);

  return (
    <button
      className="bg-white py-4 px-4 my-4 w-full rounded-md outline-none focus:outline-none active:outline-none"
      onClick={() => navigate(`/restaurant/${restaurant.id}`)}
    >
      <p className="text-black font-bold text-left mb-2">{restaurant.name}</p>
      <div className="flex justify-between">
        <p className="text-black text-left mb-4 uppercase">{restaurant.cusine}</p>
        <div className="flex">
          {restaurantIsOpen ? (
            <>
              {" "}
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=" mt-1 mr-1"
              >
                <ellipse cx="7" cy="7.5" rx="7" ry="7.5" fill="#2A6041" />
              </svg>
              <span>Open</span>
            </>
          ) : (
            <>
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=" mt-1 mr-1"
              >
                <ellipse cx="7" cy="7.5" rx="7" ry="7.5" fill="#E50000" />
              </svg>
              <span>Closed</span>
            </>
          )}
        </div>
      </div>
      <div
        className="h-48 bg-center w-auto bg-cover"
        style={{ backgroundImage: `url('${restaurant?.mainImage}')` }}
      />
      <p className="mt-1.5 text-left">
        {restaurant?.address?.street},&nbsp;
        {restaurant?.address?.city}, <br />
        {restaurant?.address?.state} {restaurant?.address?.zipCode}
      </p>
    </button>
  );
};

export default function RestaurantList() {
  const firestore = useFirestore();
  const restaurantCollection = collection(firestore, "Restaurants");
  const restaurantQuery = query(restaurantCollection);
  let navigate = useNavigate();

  const { status, data: Restaurants } = useFirestoreCollectionData(
    restaurantQuery,
    {
      idField: "id",
    }
  );
  return (
    <div className="px-4 pt-4 h-screen">
      <h2 className="font-bold text-xl">Explore Restaurants</h2>
      <div className="mt-4">
        {status === "loading" ? (
          <LoadingSpinner />
        ) : (
          Restaurants?.map((restaurant: any, index: number) => (
            <RestaurantCard
              restaurant={restaurant}
              navigate={navigate}
              key={restaurant.id + index}
            />
          ))
        )}
      </div>
    </div>
  );
}
