import { Transition, Dialog } from "@headlessui/react";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime, Duration } from "luxon";

function WaitTimeConfirmation({
  activated,
  setActivated,
  quotedWait,
  timeOfQuote,
}: {
  activated: boolean;
  setActivated: Function;
  quotedWait: number;
  timeOfQuote: number;
}) {
  const [quotedWaitTime, setQuotedWaitTime] = useState("");
  const [cancelRequest, setCancelRequest] = useState(false);
  const { restaurantId, orderId } = useParams();
  const db = getFirestore();
  const navigate = useNavigate();

  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);

  const cancelButtonRef = useRef(null);

  let interval: any;

  const milliSecondTimeConversion = useCallback(() => {
    if (!quotedWait) {
      return;
    }
    // Convert to hours and minutes
    const duration = Duration.fromMillis(quotedWait - timeOfQuote);

    // Get the current date and time
    const now = DateTime.local();

    // Add the time object to the current date and time
    const result = now.plus(duration).toFormat("hh:mm a");
    setQuotedWaitTime(result);

    console.log(result);
  }, [quotedWait]);

  const handleConfirm = async () => {
    await updateDoc(orderInfoRef, {
      status: "waiting",
      quotedWait: DateTime.fromMillis(quotedWait)
        .plus(Duration.fromMillis(DateTime.local().valueOf() - timeOfQuote))
        .valueOf(),
      updatedAt: DateTime.local().valueOf(),
    });
    setActivated(false);
  };

  const handleCancel = async () => {
    await updateDoc(orderInfoRef, {
      status: "deleted",
      customerCanceled: true,
    });
    setActivated(false);
    navigate("/");
  };

  // refresh the millisecond conversion when current time changes by 1 second
  useEffect(() => {
    milliSecondTimeConversion();
    if (activated) {
      interval = setInterval(() => {
        milliSecondTimeConversion();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [quotedWait, activated]);

  // clear interval when activated is false
  useEffect(() => {
    if (!activated) {
      clearInterval(interval);
    }
  }, [activated]);

  return (
    <Transition.Root show={activated} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => false}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className="relative transform rounded-lg border bg-white 
                px-8 pt-5 pb-4 text-left shadow-xl transition-all"
              >
                {cancelRequest ? (
                  <>
                    <div className="mt-3">
                      <Dialog.Title
                        as="h2"
                        className="text-2xl font-bold whitespace-pre text-center"
                      >
                        Cancel Request
                      </Dialog.Title>
                    </div>
                    <p className="mt-2">
                      Are you sure you want to cancel your waitlist request?
                    </p>
                    <div className="mt-6 mb-5">
                      <button
                        className="bg-wait-time-button rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                        onClick={() => handleCancel()}
                      >
                        <span className="text-black text-lg font-bold inline">
                          Yes
                        </span>
                      </button>
                    </div>
                    <div className="mt-2 mb-5">
                      <button
                        className="bg-gray rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                        onClick={() => setCancelRequest(false)}
                      >
                        <span className="text-black text-lg font-bold inline">
                          No
                        </span>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-3">
                      <Dialog.Title
                        as="h2"
                        className="text-2xl font-bold whitespace-pre"
                      >
                        Estimated Seating Time
                      </Dialog.Title>
                    </div>
                    <div className="bg-[#F7F7F7] py-4 w-auto flex flex-row mt-6 justify-center">
                      <div className="mx-4">
                        <p className="font-bold text-3xl flex justify-center">
                          {quotedWaitTime}
                        </p>
                      </div>
                    </div>
                    <div className="mt-6 mb-5">
                      <button
                        className="bg-wait-time-button rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                        onClick={() => handleConfirm()}
                      >
                        <span className="text-black text-lg font-bold inline">
                          Confirm
                        </span>
                      </button>
                    </div>
                    <div className="mt-2 mb-5">
                      <button
                        className="bg-gray rounded-3xl py-3 w-full block text-center outline-none disabled:bg-eatout-light-gray"
                        onClick={() => setCancelRequest(true)}
                      >
                        <span className="text-black text-lg font-bold inline">
                          Remove from waitlist
                        </span>
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default WaitTimeConfirmation;
