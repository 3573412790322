import { useCallback, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Item } from "../../../types/Item";
import { DocumentData } from "firebase/firestore";
import AddToOrderModal from "../../Components/modals/AddToOrder";

function RecommendedSlider({
  orderInfo,
  db,
  recommendedItems,
}: {
  orderInfo: DocumentData;
  db: any;
  recommendedItems: any;
}) {
  const [orderItem, setOrderItem] = useState<Item>({
    id: "",
    name: "",
    description: "",
    price: 0,
    image: "",
    classification: "app",
  });
  const [openOrderModal, setOpenOrderModal] = useState(false);

  const recommendedItemsList = useCallback(() => {
    // filter out any items
    const filteredList: any = recommendedItems?.filter(
      (rItem: any) =>
        !orderInfo?.items.find(
          (orderItem: Item) => orderItem.name === rItem.name
        )
    );
    return filteredList;
  }, [orderInfo?.items, recommendedItems]);

  return (
    <>
      <AddToOrderModal
        activated={openOrderModal}
        setActivated={setOpenOrderModal}
        item={orderItem}
        db={db}
      />
      {recommendedItemsList()?.length ? (
        <div className=" w-full bg-eatout-green py-2 mt-6">
          <p className="text-white font-bold px-4 pt-2">
            Don't miss our favorites!
          </p>
          <div className="py-4 -ml-20">
            <Swiper spaceBetween={0} slidesPerView={2} centeredSlides={true}>
              {recommendedItemsList().map((rItem: any, index: number) => {
                return (
                  <SwiperSlide key={rItem.name + index}>
                    <button
                      className="bg-white rounded-xl py-2 px-4 w-52 h-24"
                      onClick={() => {
                        if (!orderInfo?.submitted) {
                          setOrderItem(rItem);
                          setOpenOrderModal(true);
                        }
                      }}
                      key={rItem.id}
                    >
                      <div className="px-2 flex justify-between">
                        <div className="w-3/4">
                          <p className="font-bold mb-1 text-sm text-left">{rItem.name}</p>
                        </div>
                        {orderInfo?.submitted ? (
                          <p className=" my-1 text-sm">${rItem.price}</p>
                        ) : (
                          <div className="pl-1 align-top">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="focus:outline-none focus:bg-transparent ml-2"
                            >
                              <path
                                d="M22.5 16.5H16.5V22.5H13.5V16.5H7.5V13.5H13.5V7.5H16.5V13.5H22.5M15 0C13.0302 0 11.0796 0.387987 9.25975 1.14181C7.43986 1.89563 5.78628 3.00052 4.3934 4.3934C1.58035 7.20644 0 11.0218 0 15C0 18.9782 1.58035 22.7936 4.3934 25.6066C5.78628 26.9995 7.43986 28.1044 9.25975 28.8582C11.0796 29.612 13.0302 30 15 30C18.9782 30 22.7936 28.4196 25.6066 25.6066C28.4196 22.7936 30 18.9782 30 15C30 13.0302 29.612 11.0796 28.8582 9.25975C28.1044 7.43986 26.9995 5.78628 25.6066 4.3934C24.2137 3.00052 22.5601 1.89563 20.7403 1.14181C18.9204 0.387987 16.9698 0 15 0Z"
                                fill="#F6AE2D"
                              />
                            </svg>
                            <p className=" mt-2 text-sm">${rItem.price}</p>
                          </div>
                        )}
                      </div>
                    </button>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default RecommendedSlider;
