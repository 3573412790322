import { getFirestore, doc } from "firebase/firestore";
import { Duration, DateTime } from "luxon";
import { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestoreDocData } from "reactfire";
import { LoadingSpinner } from "../../Components/Spinner";
import Grapefruit from "../../assets/svgs/Grapefruit.svg";
import Pancake from "../../assets/svgs/Pancake.svg";
import { grabFirstName } from "../../common";
import TableIsReady from "../../Components/modals/TableIsReady";

export default function OrderConfirmation() {
  let navigate = useNavigate();
  const [quotedWaitTime, setQuotedWaitTime] = useState("");
  const [openTableIsReadyModal, setOpenTableIsReadyModal] = useState(false);
  const db = getFirestore();
  const { restaurantId, orderId } = useParams();
  const orderInfoRef = doc(db, `Restaurants/${restaurantId}/Orders/${orderId}`);

  const { status, data: orderInfo } = useFirestoreDocData(orderInfoRef, {
    idField: "id",
  });

  const milliSecondTimeConversion = useCallback(() => {
    // Convert to hours and minutes
    const duration = Duration.fromMillis(
      orderInfo?.delaySubmitted
        ? orderInfo.delaySubmitted - orderInfo?.timeOfQuote
        : orderInfo?.quotedWait - orderInfo?.timeOfQuote
    );
    const hours = duration.hours;
    const minutes = duration.minutes;

    // Construct a time object with the calculated hours and minutes
    const time = DateTime.fromObject({ hour: hours, minute: minutes });

    // Get the current date and time
    const now = DateTime.fromMillis(orderInfo?.timeOfQuote);

    // Add the time object to the current date and time
    const result = now.plus(duration).toFormat("hh:mm a");
    setQuotedWaitTime(result);
  }, [
    orderInfo?.quotedWait,
    orderInfo?.delaySubmitted,
    orderInfo?.timeOfQuote,
  ]);

  useEffect(() => {
    if (orderInfo) {
      milliSecondTimeConversion();
    }
  }, [orderInfo, milliSecondTimeConversion]);

  useEffect(() => {
    if (orderInfo?.status === "notified" && orderInfo?.customerConfirmed) {
      navigate(`${restaurantId}/order/${orderId}/completed`);
    }
    if (orderInfo?.status === "notified" && !orderInfo?.customerConfirmed) {
      setOpenTableIsReadyModal(true);
    }
    if (orderInfo?.customerCanceled) {
      navigate("/");
      return () => {};
    }

    if (orderInfo?.status === "deleted" && orderInfo?.reasonForDecline) {
      navigate(`/`);
      return () => {};
    }
  }, [orderInfo?.status, orderInfo?.customerCanceled, navigate, restaurantId, orderId, orderInfo?.reasonForDecline]);

  return (
    <>
      <TableIsReady
        activated={openTableIsReadyModal}
        setActivated={setOpenTableIsReadyModal}
      />
      <div className="flex min-h-screen justify-center text-center items-center p-0">
        {status === "loading" ? (
          <LoadingSpinner />
        ) : (
          <div
            className="relative min-h-[850px] transform w-screen overflow-hidden border dark:bg-dark-primary-background bg-white
        px-6 pt-2 pb-12 text-left shadow-xl transition-all max-w-lg"
          >
            <div className=" absolute top-0 right-0">
              <img src={Pancake} alt="Pancake" />
            </div>

            <div className="border border-eatout-ellipse-border-gray rounded-[50%] h-56 w-56 absolute -right-[55px] -top-[55px]" />
            <div className=" mb-5 pt-28">
              <p className="font-bold text-2xl text-green-text-title w-4/6 leading-9">
                {grabFirstName(orderInfo.name)}, Your order has been submitted!
              </p>
            </div>
            <p className="font-bold text-lg">
              We’ll start preparing when your table is ready.
            </p>
            <div className="bg-[#F7F7F7] py-4 w-auto flex flex-row mt-6">
              <div className="mx-4">
                <p className=" font-normal mb-3">Estimated Seating Time</p>
                <p className="font-bold text-3xl inline">{quotedWaitTime}</p>
              </div>
            </div>
            <button
              className="bg-wait-time-button rounded-3xl py-3 w-full block text-center mt-10 outline-none disabled:bg-eatout-light-gray"
              onClick={() =>
                navigate(`/${restaurantId}/order/${orderId}/review`)
              }
            >
              <span className="text-black text-lg font-bold inline">
                View/Edit Order
              </span>
            </button>
            <div className=" absolute bottom-0 left-0">
              <img src={Grapefruit} alt="grapefruit" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
